import Product from "types/product";
import tg from "utils/tg";
const tg_id = tg.initDataUnsafe.user?.id || process.env.REACT_APP_TEST_ID;
interface Responce {
  address: string;
  amount: number;
  comment: string;
  status: number;
  transaction_id: number;
  seconds: number;
}
export default async (id:Product['id']):Promise<Responce | null> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += "transaction_create";
    API_URL.searchParams.set("tg_id", String(tg_id));
    API_URL.searchParams.set("product_id", String(id));

    const responce = await fetch(API_URL)
    return await responce.json()
  } catch (error) {
    console.log("🚀 ~ error:", error)
    return null
  }
};
