import tg from "utils/tg";
import UserData from "types/user-data";
import Task from "types/task";
const tg_id = tg.initDataUnsafe.user?.id || process.env.REACT_APP_TEST_ID;

interface Responce {
  status: 1 | 2;
  balance: number;
}
export default async (id:string):Promise<Responce | null> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += "task_done";
    API_URL.searchParams.set("tg_id", String(tg_id));
    API_URL.searchParams.set("task_id", String(id));

    const responce = await fetch(API_URL)
    return await responce.json()
  } catch (error) {
    console.log("🚀 ~ error:", error)
    return null
  }
};
